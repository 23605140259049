<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link to="/" class="brand-logo">
                <img
                    src="@/assets/companyicons/logo.png"
                    width="150px"
                    alt=""
                />
            </b-link>

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="w-100 d-lg-flex align-items-center justify-content-center px-5"
                >
                    <b-img fluid :src="imgUrl" alt="Login V2" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        {{ $t('LoginPage.CardTitle') }} 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{ $t('LoginPage.CardSubtitle') }}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="validationForm"
                        >
                            <!-- email -->
                            <b-form-group
                                :label="$t('LoginPage.EmailLabel')"
                                label-for="login-email"
                            >
                                <validation-provider
                                    #default="{errors}"
                                    :name="$t('LoginPage.EmailLabel')"
                                    vid="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="login-email"
                                        v-model="userEmail"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="login-email"
                                        placeholder="john@example.com"
                                    />
                                    <small class="text-danger">
                                        {{ errors[0] }}
                                    </small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">
                                        {{ $t('LoginPage.PasswordLabel') }}
                                    </label>

                                    <b-link :to="{name: 'forgot-password'}">
                                        <small>
                                            {{ $t('LoginPage.ForgotPassword') }}
                                        </small>
                                    </b-link>
                                </div>
                                <validation-provider
                                    #default="{errors}"
                                    name="Password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="{
                                            'is-invalid': errors.length > 0,
                                        }"
                                    >
                                        <b-form-input
                                            id="login-password"
                                            v-model="password"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="············"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">
                                        {{ errors[0] }}
                                    </small>
                                </validation-provider>
                            </b-form-group>

                            <!-- checkbox -->
                            <!-- <b-form-group>
                                <b-form-checkbox
                                    id="remember-me"
                                    v-model="status"
                                    name="checkbox-1"
                                >
                                    {{ $t('LoginPage.RememberMe') }}
                                </b-form-checkbox>
                            </b-form-group> -->

                            <!-- submit buttons -->
                            <b-button type="submit" variant="primary" block>
                                {{ $t('LoginPage.Button') }}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <b-card-text class="text-center mt-2">
                        <span> {{ $t('LoginPage.NewOnPlatform') }}</span>
                        <b-link href="https://apps.fortnox.se/fs/fs/login.php?relay=%23%2Fmarketplace%2Fapp-details%2F51We0QQmNTAE">
                            <span
                                >&nbsp;
                                {{ $t('LoginPage.CreateAccount') }}</span
                            >
                        </b-link>
                    </b-card-text>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import {ValidationProvider, ValidationObserver} from 'vee-validate';

import {required, email} from '@validations';
import {togglePasswordVisibility} from '@core/mixins/ui/forms';
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            password: '',
            userEmail: '',
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            // validation rulesimport store from '@/store/index'
            required,
            email,
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password'
                ? 'EyeIcon'
                : 'EyeOffIcon';
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
                return this.sideImg;
            }
            return this.sideImg;
        },
    },
    methods: {
        validationForm() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.$useJwt
                        .login({
                            email: this.userEmail,
                            password: this.password,
                        })
                        .then(res => {
                            const {data} = res;

                            if (res.status == 401) {
                                this.popup(
                                    res.message,
                                    'danger',
                                    this.$t('Message.Failed'),
                                    'AlertTriangleIcon'
                                );
                                this.$refs.loginValidation.setErrors({
                                    email: [res.message],
                                });
                            } else {
                                this.$useJwt.setToken(data.data.token);
                                this.$useJwt.setRefreshToken(data.data.token);
                                localStorage.setItem(
                                    'userData',
                                    JSON.stringify(data.data)
                                );

                                this.$router
                                    .push({name: 'dashboard'})
                                    .then(() => {
                                        this.popup(
                                            this.$t('LoginPage.WelcomeMessage'),
                                            'success',
                                            `${this.$t('Message.welcome')} ${
                                                data.data.full_name
                                            }`,
                                            'CoffeeIcon'
                                        );
                                    });
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                let msg =
                                    localStorage.getItem('mainLang') == 'sv'
                                        ? error.response.data.message
                                        : error.response.data.eng_message;

                                this.$refs.loginValidation.setErrors({
                                    email: [msg],
                                });
                                this.popup(
                                    msg,
                                    'danger',
                                    this.$t('Message.Failed'),
                                    'AlertTriangleIcon'
                                );
                            }
                        });
                }
            });
        },

        popup(message, variant, title, icon) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                    text: message,
                },
            });
        },
    },

    mounted() {
        this.locale = localStorage.getItem('mainLang');
        if (this.$route.query.q) {
            this.popup(
                this.$t('Error.TokenExpired.Message'),
                'danger',
                this.$t('Error.TokenExpired.Title'),
                'AlertTriangleIcon'
            );
        }
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
